import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { MachineStore, MachinesModelRef } from "../domain/machines.model";
import { ApplicationResponse } from "@src/common/domain/common.model";
import api from "@src/common/services/api.service";
import AppConstants from "@src/utils/app.constants";

export const fetchMachinesList = createAsyncThunk<any[], void>(
  "machines/fetchMachines",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get<ApplicationResponse<MachinesModelRef[]>>(
        `${AppConstants.api}/machine`
      );

      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      return response.data.data || [];
      // return [
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      //   {
      //     name: "1/2 NAT 3",
      //     targetProductionPerHour: 2000,
      //     performanceTarget: "70%",
      //     availabilityTarget: "70%",
      //     qualityTarget: "98%",
      //     OEETarget: "70%",
      //     shed: "Shed 1",
      //   },
      // ];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);
export const addMachine = createAsyncThunk(
  "machines/addMachine",
  async (machine: any, { rejectWithValue }) => {
    console.log("Adding machine");
    const response = await api.post(`${AppConstants.api}/machine`, machine);
    return response.data.data;
  }
);

export const deleteMachine = createAsyncThunk<void, string>(
  "machine/delete",
  async (id: any) => {
    const response = await api.delete<ApplicationResponse<void>>(
      `${AppConstants.api}/machine/${id}`
    );
    return response.data.data;
  }
);

export const updateMachine = createAsyncThunk(
  "machines/updateMachine",
  async (
    { id, machine }: { id: string; machine: any },
    { rejectWithValue }
  ) => {
    const response = await api.put(
      `${AppConstants.api}/machine/${id}`,
      machine
    );
    return response.data.data;
  }
);

// export const

const machinesSlice = createSlice({
  name: "machines",
  initialState: {
    minimal: { status: "loading", error: null, data: [] },
  } as MachineStore,
  reducers: {
    // updateMachinesList: (state, action) => {
    //   state.minimal.data.push(action.payload);
    // },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachinesList.pending, (state, action) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchMachinesList.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchMachinesList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      });
    builder
      .addCase(addMachine.pending, (state, action) => {
        state.minimal.status = "loading";
        state.minimal.error = null;
      })
      .addCase(addMachine.fulfilled, (state, action) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
      })
      .addCase(addMachine.rejected, (state, action) => {
        state.minimal.status = "failed";
        state.minimal.error = null;
      });
    builder
      .addCase(updateMachine.pending, (state, action) => {
        state.minimal.status = "loading";
        state.minimal.error = null;
      })
      .addCase(updateMachine.fulfilled, (state, action) => {
        state.minimal.status = "idle";
        state.minimal.error = null;
      })
      .addCase(updateMachine.rejected, (state, action) => {
        state.minimal.status = "failed";
        state.minimal.error = null;
      });
  },
});
// export const { updateMachinesList } = machinesSlice.actions;

export default machinesSlice.reducer;
