import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
// import { NewUsersModelRef, UsersStore } from '@src/views/users/domain/user.model';
import React from "react";
import { UsersModelRef, UsersStore } from "../domain/user.model";
import AppConstants from "@src/utils/app.constants";
import api from "@src/common/services/api.service";
import { ApplicationResponse } from "@src/common/domain/common.model";
import { ProductsModelRef } from "@src/views/products/domain/products.model";

function transformUsersToRoles(users: UsersModelRef[]) {
  const result: {
    operators: UsersModelRef[];
    inspectors: UsersModelRef[];
    supervisor: UsersModelRef[];
    admin: UsersModelRef[];
  } = {
    operators: [],
    inspectors: [],
    supervisor: [],
    admin: []
  };

  users.forEach((user) => {
    if (user.role === "OPERATOR") {
      result.operators.push(user);
    } else if (user.role === "INSPECTOR") {
      result.inspectors.push(user);
    }
    else if (user.role === "SUPERVISOR") {
      result.supervisor.push(user);}
    
    else if (user.role === "ADMIN") {
      result.admin.push(user);
    }
    
  });

  return result;
}

export const fetchUsersList = createAsyncThunk<any, void>(
  "users/fetchUsers",
  async (_, { rejectWithValue }) => {
    try {
      const url = `${AppConstants.api}/user`;
      const response = await api.get<ApplicationResponse<UsersModelRef[]>>(url);
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }

      console.log("Response", response.data.data);

      const data = transformUsersToRoles(response.data.data!);
      return data;
    } catch (err: any) {
      console.log(
        "Catch block is executed only when there is some error in the code"
      );
      console.log("Error", err.response);
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }

      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

export const uploadAndUpdateUser = createAsyncThunk<
  void,
  {
    payload: UsersModelRef;
    id?: string;
  }
>("user/uploadAndUpdate", async ({ payload, id }, thunkAPI) => {
  try {
    const url = `${AppConstants.api}/user`;

    // const apiPayload = {
    //   // id: payload.id,
    //   drawing_no: payload.drawing_no,
    //   description: payload.description,
    //   rm_grade: payload.rm_grade,
    //   rm_size: payload.rm_size,
    //   weight_per_piece: payload.weight_per_piece,
    // };
    // const apiPayload = {};
    if (id) {
      const response = await api.put<ApplicationResponse<void>>(
        `${AppConstants.api}/user/${id}`,
        payload
      );
      return response.data.data;
    } else {
      const response = await api.post<ApplicationResponse<void>>(url, payload);
      return response.data.data;
    }
  } catch (err: any) {
    if (!err.response) {
      return thunkAPI.rejectWithValue(err.response.data);
    }

    return thunkAPI.rejectWithValue({
      error: {
        message: err.response.data.message,
        code: err.response.data.code,
      },
    });
  }
});

export const deleteUser = createAsyncThunk<void, string>(
  "user/delete",
  async (id: any) => {
    const response = await api.delete<ApplicationResponse<void>>(
      `${AppConstants.api}/user/${id}`
    );
    return response.data.data;
  }
);

const usersSlice = createSlice({
  name: "users",
  initialState: {
    minimal: {
      status: "loading",
      error: null,
      data: {
        operators: [],
        inspectors: [],
        supervisor: [],
        admin: []
      },
    },
  } as UsersStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchUsersList.pending, (state, action) => {
        state.minimal = {
          status: "loading",
          data: {
            operators: [],
            inspectors: [],
            supervisor:[],
            admin: []
          },
          error: null,
        };
      })
      .addCase(fetchUsersList.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchUsersList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: {
            operators: [],
            inspectors: [],
            supervisor: [],
            admin: []
          },
          error: action.error,
        };
      });
  },
});
// export const { updateUsersList } = usersSlice.actions;

export default usersSlice.reducer;
