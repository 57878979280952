import { combineReducers } from "@reduxjs/toolkit";
import dialogReducer from "@slices/dialog.slice";
import authReducer from "@slices/auth.slice";
import configReducer from "@slices/config.slice";
import usersReducer from "@src/views/users/slices/users.slice";

import brokerReducer from "@slices/broker.slice";

import machinesSlice from "@src/views/machines/slices/machines.slice";
import machineStatusSlice from "@src/views/machine-status/slices/machine-status.slice";
import downloadSlice from "../slices/download.slice";
import productionPlanningSlice from "@src/views/production-planning/slices/production-planning.slice";
import customersSlice from "../slices/customers.slice";
import productsSlice from "../slices/products.slice";
import toolsSlice from "../slices/tools.slice";
import machinePerformanceRecordSlice from "@src/views/machine-performance-record/slices/machine-performance-record.slice";
import planningSlice from "@src/views/planning/slices/planning.slice";
import toolStatusSlice from "../slices/tool-status.slice";
import bestAndWorstSlice from "../slices/best-and-worst.slice";
import checksheetSlice from "@src/views/check-sheet/slice/checksheet.slice";
import locationSlice from "../slices/location.slice";
import stationsReducer from "../slices/station.slice";
import machineHourlyStatusSlice from "@src/views/machine-status/slices/machine-hourly-status.slice";

const rootReducer = combineReducers({
  dialogs: dialogReducer,
  auth: authReducer,
  config: configReducer,
  broker: brokerReducer,
  users: usersReducer,
  // dashboard: dashboardReducer,
  machines: machinesSlice,
  machineStatus: machineStatusSlice,
  machineHourlyStatus: machineHourlyStatusSlice,
  download: downloadSlice,
  productionPlanning: productionPlanningSlice,
  planning: planningSlice,
  customers: customersSlice,
  products: productsSlice,
  tools: toolsSlice,
  machinePerformanceRecord: machinePerformanceRecordSlice,
  toolStatus: toolStatusSlice,
  bestAndWorst: bestAndWorstSlice,
  checksheet: checksheetSlice,
  locations: locationSlice,
  stations: stationsReducer,
});

export default rootReducer;
