import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApplicationResponse } from "@src/common/domain/common.model";
import api from "@src/common/services/api.service";
import AppConstants from "@src/utils/app.constants";
import { SearchModelRef } from "@src/views/products/domain/products.model";
// import { ToolsStore } from "../domain/tools.model";
import {
  NewToolsModelRef,
  ToolsModelRef,
  ToolsStore,
} from "@src/views/tools/domain/tools.model";
import { id } from "date-fns/locale";

export const searchToolsList = createAsyncThunk<any[], void>(
  "tools/searchTools",
  async (_, { rejectWithValue }) => {
    try {
      const url = `${AppConstants.api}/tool/search`;
      const response = await api.get<ApplicationResponse<SearchModelRef[]>>(
        url
      );
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      console.log("Response", response.data.data);
      return response.data.data as SearchModelRef[];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }

      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

export const fetchToolsList = createAsyncThunk<any[], void>(
  "tools/fetchTools",
  async (_, { rejectWithValue }) => {
    try {
      const url = `${AppConstants.api}/tool`;
      const response = await api.get<ApplicationResponse<ToolsModelRef[]>>(url);

      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      return response.data.data || [];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

export const deleteTool = createAsyncThunk<void, string>(
  "tool/delete",
  async (id: any) => {
    const response = await api.delete<ApplicationResponse<void>>(
      `${AppConstants.api}/tool/${id}`
    );
    return response.data.data;
  }
);

export const addTool = createAsyncThunk(
  "tools/addTool",
  async (tool: any, { rejectWithValue }) => {
    try {
      const response = await api.post(`${AppConstants.api}/tool`, tool);
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }

      return response.data.data;
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);
export const updateTool = createAsyncThunk(
  "tools/updateTool",
  async ({ id, tool }: { id: string; tool: any }, { rejectWithValue }) => {
    try {
      const response = await api.put(`${AppConstants.api}/tool/${id}`, tool);
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }

      return response.data.data;
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

// export const uploadTool= createAsyncThunk<void, {
//   payload: NewToolsModelRef
// }>("tool/upload",
//   async ({ payload }, thunkAPI) => {
//     try {

//       const url = `${AppConstants.api}/tool`

//       const apiPayload = {
//         id: payload.id,
//         tool_name: payload.tool_name,
//         tool_life: payload.tool_life,
//       };
//       if (payload.id) {

//         const response = await api.put<ApplicationResponse<void>>(url, apiPayload)
//         return response.data.data
//       } else {
//         const response = await api.post<ApplicationResponse<void>>(url, apiPayload)
//         return response.data.data
//       }
//     } catch (err: any) {
//       if (!err.response) {
//         return thunkAPI.rejectWithValue(err.response.data)
//       }

//       return thunkAPI.rejectWithValue({
//         error: {
//           message: err.response.data.message,
//           code: err.response.data.code
//         }
//       })
//     }
//   })

export const fetchToolStatusList = createAsyncThunk<any[], void>(
  "toolsStatus/fetchToolsStatus",
  async () => {
    return [
      {
        id: "1",
        machineName: "1/2 NAT 1",
        drawing_no: "S072-31",
        description: "M10X1.5X30 HEX HEAD SCREW",
        station1: "ok",
        station2: "ok",
        station3: "ok",
        station4: "nok",
      },
      {
        id: "2",
        machineName: "3/4 NAT 2",
        drawing_no: "S072-32",
        description: "M12X1.75X40 HEX HEAD SCREW",
        station1: "ok",
        station2: "nok",
        station3: "ok",
        station4: "ok",
      },
      {
        id: "3",
        machineName: "1/2 NAT 3",
        drawing_no: "S072-33",
        description: "M8X1.25X25 HEX HEAD SCREW",
        station1: "ok",
        station2: "ok",
        station3: "nok",
        station4: "ok",
      },
      {
        id: "4",
        machineName: "1/4 NAT 4",
        drawing_no: "S072-34",
        description: "M6X1.0X20 HEX HEAD SCREW",
        station1: "nok",
        station2: "ok",
        station3: "ok",
        station4: "nok",
      },
    ];
  }
);

const toolsSlice = createSlice({
  name: "tools",
  initialState: {
    minimal: { data: [], status: "loading", error: null },
    toolStatusMinimal: { data: [], status: "loading", error: null },
  } as ToolsStore,
  reducers: {
    updateToolsList: (state, action) => {
      state.minimal.data.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchToolsList.pending, (state, action) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchToolsList.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchToolsList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      })
      .addCase(fetchToolStatusList.pending, (state, action) => {
        state.toolStatusMinimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchToolStatusList.fulfilled, (state, action) => {
        state.toolStatusMinimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchToolStatusList.rejected, (state, action) => {
        state.toolStatusMinimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      })
      .addCase(searchToolsList.pending, (state, action) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(searchToolsList.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(searchToolsList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      });
  },
});
export const { updateToolsList } = toolsSlice.actions;

export default toolsSlice.reducer;
