import {AuthStore} from "@auth/domain/auth.config"


export const isAuthenticated = (auth: AuthStore) => auth.status === 'LOGGED_IN'

export type ConfigurationStatus = "INITIALIZED" | "NOT_CONFIGURED" | "CONFIGURED"
export type FeatureTag = ""
export type Platform = "ANDROID" | "WINDOWS"


export interface FeatureRef {
    id: string,
    tag: FeatureTag,
    description: string,
    title: string,
    count: number,
    isInherent?: boolean
    supportedPlatforms: Platform[]
}

export interface ClientConfigurationRef {
    clientId: string;
    status: ConfigurationStatus;
    features: FeatureRef[];
}


export function getTokenExpirationTime(userData: any): number {
    // Implement this function to return the expiration time in milliseconds
    // You might need to parse the JWT token or use the expiresAt value from userData
    return userData.expiresAt * 1000; // Assuming expiresAt is in seconds
}
