import { createSlice, PayloadAction } from '@reduxjs/toolkit';

interface BrokerState {
  messages: Record<string, any>;
  isConnected: boolean;
}

const initialState: BrokerState = {
  messages: {},
  isConnected: false,
};

const brokerSlice = createSlice({
  name: 'broker',
  initialState,
  reducers: {
    updateMessage: (state, action: PayloadAction<{ topic: string; message: any }>) => {
      state.messages[action.payload.topic] = action.payload.message;
    },
    setConnectionStatus: (state, action: PayloadAction<boolean>) => {
      state.isConnected = action.payload;
    },
  },
});

export const { updateMessage, setConnectionStatus } = brokerSlice.actions;
export default brokerSlice.reducer;