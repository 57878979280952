import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ToolStatusModelRef, ToolStatusStore } from "../../views/tool-status/domain/tool-status.model";
import AppConstants from "@src/utils/app.constants";
import api from "@src/common/services/api.service";
import { ApplicationResponse } from "@src/common/domain/common.model";

export const fetchToolStatusList = createAsyncThunk<any[], void>(
  "toolStatus/fetchToolStatus",
  async (_,{rejectWithValue}) => {
try {
  const url = `${AppConstants.api}/machine/tool-configure`
    const response = await api.get<ApplicationResponse<ToolStatusModelRef[]>>(url)
 if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
    return response.data.data || []
} catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  
  }
);




export const updateToolStatus = createAsyncThunk(
  "toolStatus/updateToolStatus",
  async (
    { id, status }: { id: string; status: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.put(
        `${AppConstants.api}/machine/tool-status`,
        status
      );
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
        console.log(response.data.data);
      return response.data.data;
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);
export const updateToolChange = createAsyncThunk(
  "toolChange/updateToolChange",
  async (
    { id, tools }: { id: string; tools: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.put(
        `${AppConstants.api}/machine/tool-change`,
        tools
      );
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
        console.log(response.data.data);
      return response.data.data;
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

const toolStatusSlice = createSlice({
    name: "toolStatus",
    initialState: {minimal:{status: "loading",error: null,data:[]}} as ToolStatusStore,
    reducers:{

    },
    extraReducers:(builder) => {
        builder
             .addCase(fetchToolStatusList.pending, (state, action) => {
                state.minimal = {
                    status: "loading",
                    data: [],
                    error: null
                }
            })
            .addCase(fetchToolStatusList.fulfilled, (state, action) => {
                state.minimal = {
                    status: "idle",
                    data: action.payload,
                    error: null
                }
            })
            .addCase(fetchToolStatusList.rejected, (state, action) => {
                state.minimal = {
                    status: "failed",
                    data: [],
                    error: action.error
                }
            })

    }
})

export default toolStatusSlice.reducer