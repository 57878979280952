import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ApplicationResponse } from "@src/common/domain/common.model";
import { StationModelRef, StationStore } from "@src/common/domain/common.props";
import api from "@src/common/services/api.service";
import AppConstants from "@src/utils/app.constants";
import { v4 as uuid } from "uuid";

export const fetchStationsList = createAsyncThunk<StationModelRef[], string>(
  "station/fetchStationsList",
  async (id, { rejectWithValue }) => {
    try {
      const response = await api.get<ApplicationResponse<StationModelRef[]>>(
        `${AppConstants.api}/station?machine-id=${id}`
      );
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }

      return response.data.data as StationModelRef[];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

const stationSlice = createSlice({
  name: "station",
  initialState: {
    minimal: { data: [], status: "loading", error: null },
  } as StationStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchStationsList.pending, (state) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchStationsList.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchStationsList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.payload,
        };
      });
  },
});

export default stationSlice.reducer;
