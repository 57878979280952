import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { PlanningStore, PlanningDataRef } from "../domain/planning.model";
import api from "@src/common/services/api.service";
import { ApplicationResponse } from "@src/common/domain/common.model";
import AppConstants from "@src/utils/app.constants";
import { showErrorToast } from "@src/utils/toast.utils";

export const fetchPlanList = createAsyncThunk<PlanningDataRef[], string>(
  "Plan/fetchPlan",
  async (param: string, { rejectWithValue }) => {
    console.log("Param....", param);
    try {
      const response = await api.get<ApplicationResponse<PlanningDataRef[]>>(
        `${AppConstants.api}/machine/plan?plan-type=${param}`
      );
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      return response.data.data || [];
      // return [
      //   {
      //     product_description: "hbugtyryctvu",
      //     id: "1",
      //     machine_status: "RUNNING",
      //     machine_name: "Machine A",
      //     machine_id: "123",
      //     product_id: "456",
      //     station_ids: ["789", "101"],
      //     shift_entry_ids: ["102", "103"],
      //     machine_event_id: "104",
      //     customer_id: "105",
      //     customer_name: "Customer A",
      //     drawing_number: "DN-001",
      //     rm_grade: 1.5,
      //     final_rm_size: 2.0,
      //     weight_per_piece: 0.75,
      //     availability: 95.0,
      //     performance: 90.0,
      //     quality: 85.0,
      //     quantity: 100.0,
      //     oee: 92.0,
      //     production: 500.0,
      //     rejection: 2.0,
      //   },
      // ];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

export const addPlan = createAsyncThunk(
  "Planning/addProductionPlan",
  async (payload: any, { rejectWithValue }) => {
    console.log("Adding machine");
    const response = await api.post(
      `${AppConstants.api}/machine/plan`,
      payload
    );
    if (response.data?.error !== null) {
      showErrorToast(response.data?.error?.attributes?.payload);
      return rejectWithValue(response.data.error);
    }
    return response.data.data;
  }
);

const planningSlice = createSlice({
  name: "planning",
  initialState: {
    minimal: {
      status: "loading",
      error: null,
      data: [],
    },
  } as PlanningStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchPlanList.pending, (state, action) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchPlanList.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchPlanList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      });
  },
});

export default planningSlice.reducer;
