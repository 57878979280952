import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  machinePerformanceRecordStore,
  ShiftWiseModelRef,
  DayWiseModelRef
} from "../domain/machine-performance-record";
import { ApplicationResponse } from "@src/common/domain/common.model";
import api from "@src/common/services/api.service";
import AppConstants from "@src/utils/app.constants";
import { showErrorToast } from "@src/utils/toast.utils";
import { NumberSchema } from "yup";


export const fetchShiftWiseList = createAsyncThunk<ShiftWiseModelRef[], void>(
  "shiftWise/fetchShiftWise",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get<ApplicationResponse<ShiftWiseModelRef[]>>(
        `${AppConstants.api}/plan/machine-performance`
      );
      if (response.data.error !== null) {
        showErrorToast(response.data.error.message);
        return rejectWithValue(response.data.error);
      }
      console.log("Response", response.data.data);
      return response.data.data as ShiftWiseModelRef[];

      return [
        {
          id: "tyug",
          machine_name: "Machien 1",
          machine_id: "bceiyuw",
          day_prod: 8999,
          oee: 67,
          drawing_number: "SX-107",
          drawing_desc: "SXOMKISWOS-129",
          shift_a_no_op: 1,
          shift_a_breakdown: 2,
          shift_a_no_tool: 2,
          shift_a_job_change: 4,
          shift_a_setting: 8,
          shift_a_no_rm: 9,
          shift_a_prod: 4000,
          shift_a_quantity: 1321,
          shift_b_no_op: 1,
          shift_b_breakdown: 2,
          shift_b_no_tool: 12,
          shift_b_job_change: 12,
          shift_b_setting: 23,
          shift_b_no_rm: 12,
          shift_b_prod: 4999,
          shift_b_quantity: 8767,
        },
      ] as ShiftWiseModelRef[];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

export const updateMachinePerformanceRecord = createAsyncThunk<
  void,
  { machine_id: string; shift_id: string; day_prod: number; ng_count: number }
>("update/machine-performance-record", async (payload, { rejectWithValue }) => {
  try {
    const response = await api.post<ApplicationResponse<void>>(
      `${AppConstants.api}/plan/machine-performance`,
      payload
    );
    if (response.data.error !== null) {
      showErrorToast(response?.data?.error?.attributes?.payload);
      return rejectWithValue(response.data.error);
    }
    console.log("Response", response.data.data);
  } catch (err: any) {
    if (!err.response) {
      return rejectWithValue(err.response.data);
    }
    return rejectWithValue({
      error: {
        message: err.response.data.message,
        code: err.response.data.code,
      },
    });
  }
});


// export const fetchDayWiseList = createAsyncThunk<any[], void>("dayWise/fetchDayWise",
//   async () => {
//     return [
//       {
//         id: 1,
//         machine: "Machine 1",
//         day_prod: 4000,
//         oee: "70",
//         no_operator: 2,
//         breakdown: 4,
//         no_tool: 0,
//         job_change: 0,
//         setting: 0,
//         no_rm: 0,
//         production: 18,
//         quantity: 52000,

//       },
//       {
//         id: 2,
//         machine: "Machine 2",
//         day_prod: 4000,
//         oee: "70",
//         no_operator: 2,
//         breakdown: 4,
//         no_tool: 0,
//         job_change: 0,
//         setting: 0,
//         no_rm: 0,
//         production: 18,
//         quantity: 52000,

//       },
//       {
//         id: 3,
//         machine: "Machine 3",
//         day_prod: 4000,
//         oee: "70",
//         no_operator: 2,
//         breakdown: 4,
//         no_tool: 0,
//         job_change: 0,
//         setting: 0,
//         no_rm: 0,
//         production: 18,
//         quantity: 52000,

//       },
//       {
//         id: 4,
//         machine: "Machine 4",
//         day_prod: 4000,
//         oee: "70",
//         no_operator: 2,
//         breakdown: 4,
//         no_tool: 0,
//         job_change: 0,
//         setting: 0,
//         no_rm: 0,
//         production: 18,
//         quantity: 52000,

//       },
//       {
//         id: 5,
//         machine: "Machine 5",
//         day_prod: 4000,
//         oee: "70",
//         no_operator: 2,
//         breakdown: 4,
//         no_tool: 0,
//         job_change: 0,
//         setting: 0,
//         no_rm: 0,
//         production: 18,
//         quantity: 52000,

//       },


//     ]
//   }
// )


export const fetchDayWiseList = createAsyncThunk<DayWiseModelRef[],void>(
  "dayWise/fetchDayWise",
  async(_,{rejectWithValue}) => {
    try{
      const url = `${AppConstants.api}/plan/production/daily`;
      const response = await api.get<ApplicationResponse<DayWiseModelRef[]>>(url);
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      return response.data.data || [];
    }catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
)



const machinePerformanceRecordSlice = createSlice({
  name: "machinePerformanceRecord",
  initialState: {
    dayMinimal: { status: "loading", error: null, data: [] },
    shiftMinimal: { status: "loading", error: null, data: [] },
  } as machinePerformanceRecordStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchShiftWiseList.pending, (state, action) => {
        state.shiftMinimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchShiftWiseList.fulfilled, (state, action) => {
        state.shiftMinimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchShiftWiseList.rejected, (state, action) => {
        state.shiftMinimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      })
      .addCase(fetchDayWiseList.pending, (state, action) => {
        state.dayMinimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchDayWiseList.fulfilled, (state, action) => {
        state.dayMinimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchDayWiseList.rejected, (state, action) => {
        state.dayMinimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      });
  },
});

export default machinePerformanceRecordSlice.reducer;
