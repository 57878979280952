import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { CustomersModelRef, CustomersStore, NewCustomersModelRef } from "../../views/customers/domain/customers.model";
import AppConstants from "@src/utils/app.constants";
import { ApplicationResponse } from "@src/common/domain/common.model";
import api from "@src/common/services/api.service";
import { SearchModelRef } from "@src/views/products/domain/products.model";

export const searchCustomersList = createAsyncThunk<any[], void>(
  "customers/searchCustomers",
  async (_, { rejectWithValue }) => {
    try {
      const url = `${AppConstants.api}/customer/search`;
      const response = await api.get<ApplicationResponse<SearchModelRef[]>>(
        url
      );
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      console.log("Response", response.data.data);
      return response.data.data as SearchModelRef[];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }

      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

export const fetchCustomersList = createAsyncThunk<any[], void>(
  "customers/fetchCustomers",
  async (_,{rejectWithValue}) => {
try {
  const url = `${AppConstants.api}/customer`
    const response = await api.get<ApplicationResponse<CustomersModelRef[]>>(url)
 if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
    return response.data.data || []
} catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  
  }
);



export const addCustomer = createAsyncThunk(
  "customers/addCustomer",
  async(customer:any,{rejectWithValue}) => {
    try{
  const response = await api.post(`${AppConstants.api}/customer`,customer);
    if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
  
  return response.data.data;
}
catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
    
  }
)

export const deleteCustomer = createAsyncThunk<void, string>(
  "customer/delete",
  async (id: any) => {
    const response = await api.delete<ApplicationResponse<void>>(
      `${AppConstants.api}/customer/${id}`
    );
    return response.data.data;
  }
);

export const updateCustomer = createAsyncThunk(
  "customers/updateCustomer",
  async (
    { id, customer }: { id: string; customer: any },
    { rejectWithValue }
  ) => {
    try {
      const response = await api.put(
        `${AppConstants.api}/customer/${id}`,
        customer
      );
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }

      return response.data.data;
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);


const customersSlice = createSlice({
  name: "customers",
  initialState: {
    minimal: { status: "loading", error: null, data: [] },
  } as CustomersStore,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchCustomersList.pending, (state, action) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchCustomersList.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchCustomersList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      })
      .addCase(searchCustomersList.pending, (state, action) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(searchCustomersList.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(searchCustomersList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      });
  },
});

export default customersSlice.reducer;
