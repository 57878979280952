import AppConstants from "@utils/app.constants";
import {
  ApplicationResponse,
  StateTrackedData,
} from "@common/domain/common.model";
import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "@common/services/api.service";

// export const fetchProductionDownloadList = createAsyncThunk(
//   "fetch/DownloadList",
//   async (param: any, { rejectWithValue }) => {
//     console.log(param);
//     try {
//       const response = await api.get<ApplicationResponse<any[]>>(
//         `${AppConstants.api}/fg-goods/csv?start_date=${
//           param?.startDate ? param.startDate + "T00:00:00" : ""
//         }&end_date=${param?.endDate ? param.endDate + "T00:00:00" : ""}`
//       );

//       if (response.data.status === "ERROR") {
//         // Handle error response
//         showFailedToast(`Error: ${response.data.error.message}`);
//         return rejectWithValue(response.data);
//       }
//       return response.data.data || [];
//     } catch (error) {
//       return [];
//     }
//   }
// );

const downloadSlice = createSlice({
  name: "productions",
  initialState: {
    minimal: { status: "loading", error: null, data: [] },
  },
  reducers: {
    resetDownload: (state) => {
      state.minimal = { status: "loading", error: null, data: [] };
    },
  },
});
export const { resetDownload } = downloadSlice.actions;
export default downloadSlice.reducer;