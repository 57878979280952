import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  NewProductsModelRef,
  ProductsModelRef,
  ProductsStore,
  SearchModelRef,
} from "../../views/products/domain/products.model";
import AppConstants from "@src/utils/app.constants";
import api from "@src/common/services/api.service";
import { ApplicationResponse } from "@src/common/domain/common.model";
import moment from "moment";
import { showErrorToast } from "@src/utils/toast.utils";

export const searchProuctsList = createAsyncThunk<any[], void>(
  "products/searchProducts",
  async (_, { rejectWithValue }) => {
    try {
      const url = `${AppConstants.api}/product/search`;
      const response = await api.get<ApplicationResponse<SearchModelRef[]>>(
        url
      );
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      return response.data.data as SearchModelRef[];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }

      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

export const fetchProductsList = createAsyncThunk<any[], void>(
  "products/fetchProducts",
  async (_, { rejectWithValue }) => {
    try {
      const url = `${AppConstants.api}/product`;
      const response = await api.get<ApplicationResponse<ProductsModelRef[]>>(
        url
      );
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      console.log("Response", response.data.data);
      return response.data.data || ([] as ProductsModelRef[]);
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }

      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

export const uploadAndUpdateProduct = createAsyncThunk<
  void,
  {
    payload: NewProductsModelRef;
    id?: string;
  }
>("product/uploadAndUpdate", async ({ payload, id }, thunkAPI) => {
  try {
    const url = `${AppConstants.api}/product`;

    const apiPayload = {
      // id: payload.id,
      drawing_no: payload.drawing_no,
      description: payload.description,
      rm_grade: payload.rm_grade,
      rm_size: payload.rm_size,
      weight_per_piece: payload.weight_per_piece,
    };
    if (id) {
      const response = await api.put<ApplicationResponse<void>>(
        `${AppConstants.api}/product/${id}`,
        apiPayload
      );
      return response.data.data;
    } else {
      const response = await api.post<ApplicationResponse<void>>(
        url,
        apiPayload
      );
      return response.data.data;
    }
  } catch (err: any) {
    if (!err.response) {
      return thunkAPI.rejectWithValue(err.response.data);
    }

    return thunkAPI.rejectWithValue({
      error: {
        message: err.response.data.message,
        code: err.response.data.code,
      },
    });
  }
});

export const deleteProduct = createAsyncThunk<
  void,
  {
    // payload: NewProductsModelRef;
    id?: string;
  }
>("product/delete", async (id, thunkAPI) => {
  try {
    const url = `${AppConstants.api}/product/${id}`;
    const response = await api.delete<ApplicationResponse<void>>(url);
    return response.data.data;
  } catch (err: any) {
    if (!err.response) {
      return thunkAPI.rejectWithValue(err.response.data);
    }
    return thunkAPI.rejectWithValue({
      error: {
        message: err.response.data.message,
        code: err.response.data.code,
      },
    });
  }
});

const productsSlice = createSlice({
  name: "products",
  initialState: {
    minimal: { status: "loading", error: null, data: [] },
  } as ProductsStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchProductsList.pending, (state, action) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchProductsList.fulfilled, (state, action) => {
        if (action.payload.length === 0) {
          showErrorToast("Please add Drawings first.");
        }
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchProductsList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      })
      .addCase(searchProuctsList.pending, (state, action) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(searchProuctsList.fulfilled, (state, action) => {
        if (action.payload.length === 0) {
          showErrorToast("Please add Drawings first.");
        }
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(searchProuctsList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      });
  },
});

export default productsSlice.reducer;
