import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  boltForgingQualityPlanInfo,
  BoltForgingQualityRef,
  BoltForgingRef,
  CheckSheetStore,
  ShiftModelRef,
} from "../domain/checksheet.model";
import { ApplicationResponse } from "@src/common/domain/common.model";
import AppConstants from "@src/utils/app.constants";
import api from "@src/common/services/api.service";
import { showErrorToast } from "@src/utils/toast.utils";

export const fetchBoltForgingQualityChecksheet = createAsyncThunk<
  BoltForgingQualityRef[],
  void
>(
  "boltForgingquality/fetchBoltForgingQualityChecksheet",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get<
        ApplicationResponse<BoltForgingQualityRef[]>
      >(`${AppConstants.api}/bolt-quality`);
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      return response.data.data as BoltForgingQualityRef[];
      // return [
      //   {
      //     id: "1",
      //     current_plan_id: "1",
      //     machine_name: "1/2 NAT 3",
      //     info: [
      //       {
      //         date: "05-07-2024",
      //         bolt_forging_shift_info_list: [
      //           {
      //             // id: "1",
      //             // checkpoint: "Passed",
      //             // operator: "YOG",
      //             // inspector: "KAP",
      //             // rm_size: 921.7,
      //             // customer: "TATA",
      //             // rm_grade: 7687231,
      //             // drawing_no: "SX234",
      //             // plan_id: "1",
      //           },
      //           // {
      //           //   // id: "2",
      //           //   // checkpoint: "Failed",
      //           //   // operator: "Dha",
      //           //   // inspector: "Chi",
      //           //   // rm_size: 9234.7,
      //           //   // customer: "TATA",
      //           //   // rm_grade: 982187,
      //           //   // drawing_no: "S071-30",
      //           //   // plan_id: "1",
      //           // },
      //         ],
      //       },
      //       {
      //         date: "06-07-2024",
      //         bolt_forging_shift_info_list: [
      //           {
      //             // id: "1",
      //             // checkpoint: "Passed",
      //             // operator: "YOG",
      //             // inspector: "KAP",
      //             // rm_size: 67589.7,
      //             // customer: "TATA",
      //             // rm_grade: 547657,
      //             // drawing_no: "8789gwuh",
      //             // plan_id: "1",
      //           },
      //           {
      //             id: "2",
      //             checkpoint: "Hold",
      //             operator: "Dha",
      //             inspector: "Chi",
      //             rm_size: 2139.98,
      //             customer: "TATA",
      //             rm_grade: 4678,
      //             drawing_no: "S0677H-30",
      //             plan_id: "1",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      //   {
      //     id: "1",
      //     current_plan_id: "1",
      //     machine_name: "1/2 AT 3",
      //     info: [
      //       {
      //         date: "06-07-2024",
      //         bolt_forging_shift_info_list: [
      //           {
      //             // id: "1",
      //             // checkpoint: "Passed",
      //             // operator: "YOG",
      //             // inspector: "KAP",
      //             // rm_size: 9.7,
      //             // customer: "TATA",
      //             // rm_grade: 15825,
      //             // drawing_no: "S071-30",
      //             // plan_id: "1",
      //           },
      //           {
      //             id: "2",
      //             checkpoint: "Failed",
      //             operator: "KAP",
      //             inspector: "YOG",
      //             rm_size: 9.7,
      //             customer: "TATA",
      //             rm_grade: 15825,
      //             drawing_no: "S071-30",
      //             plan_id: "1",
      //           },
      //         ],
      //       },
      //       {
      //         date: "05-07-2024",
      //         bolt_forging_shift_info_list: [
      //           {
      //             id: "1",
      //             checkpoint: "Passed",
      //             operator: "DHA",
      //             inspector: "CHI",
      //             rm_size: 459.7,
      //             customer: "TATA",
      //             rm_grade: 15825,
      //             drawing_no: "S071-30",
      //             plan_id: "1",
      //           },
      //           {
      //             id: "2",
      //             checkpoint: "Hold",
      //             operator: "CHI",
      //             inspector: "DHA",
      //             rm_size: 7689.7,
      //             customer: "TATA",
      //             rm_grade: 15825,
      //             drawing_no: "S071-30",
      //             plan_id: "1",
      //           },
      //         ],
      //       },
      //     ],
      //   },
      // ] as BoltForgingQualityRef[];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

export const fetchShiftList = createAsyncThunk<any[], void>(
  "products/fetchProducts",
  async (_, { rejectWithValue }) => {
    try {
      const url = `${AppConstants.api}/shift`;
      const response = await api.get<ApplicationResponse<ShiftModelRef[]>>(url);
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      console.log("Response", response.data.data);
      return response.data.data as ShiftModelRef[];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }

      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);
export const fetchBoltForgingChecksheet = createAsyncThunk<any[], void>(
  "boltForging/fetchBoltForgingChecksheet",
  async (_, { rejectWithValue }) => {
    try {
      const response = await api.get<ApplicationResponse<BoltForgingRef[]>>(
        `${AppConstants.api}/boltforging`
      );
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      return response.data.data as BoltForgingRef[];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

export const fillBoltForgingChecksheet = createAsyncThunk<
  void,
  { id: string; data: any }
>(
  "checksheet/fillBoltForgingChecksheet",
  async (payload, { rejectWithValue }) => {
    console.log("payloadddddddddddddddd", payload);
    try {
      const url = payload.id === "" ? null : `id=${payload.id}`;
      const response = await api.post<ApplicationResponse<void>>(
        `${AppConstants.api}/bolt-quality?${url}`,
        payload.data
      );
      if (response.data.error !== null) {
        showErrorToast();
        return rejectWithValue(response.data.error);
      }
      console.log("Response", response.data.data);
      return response.data.data;
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

// export const fetchBoltForgingQualityChecksheet = createAsyncThunk<any[], void>("boltForgingquality/fetchBoltForgingQualityChecksheet",
//     async () => {
//         return [
//             {
//               stationNo: "1",
//               toolNo: "t56",
//               toolLife: 6787,
//               actualToolLife: 67543,
//             },
//             {
//               stationNo: "2",
//               toolNo: null,
//               toolLife: null,
//               actualToolLife: null,
//             },
//             {
//               stationNo: "3",
//               toolNo: null,
//               toolLife: null,
//               actualToolLife: null,
//             },
//             {
//               stationNo: "4",
//               toolNo: null,
//               toolLife: null,
//               actualToolLife: null,
//             },
//           ],
//         },
//         {
//           id: "2",
//           customerName: "TATA",
//           machine: "1/2 NAT 3",
//           drawingNumber: "S071-30",
//           description: "M10X1.5X30 HEX HEAD SCREW",
//           rmGrade: 15825,
//           finalRMSize: 9.7,
//           stations: [
//             {
//               stationNo: "1",
//               toolNo: "t56",
//               toolLife: 50000,
//               actualToolLife: 60000,
//             },
//             {
//               stationNo: "2",
//               toolNo: "t5678",
//               toolLife: 50000,
//               actualToolLife: 60000,
//             },
//             {
//               stationNo: "3",
//               toolNo: "t76",
//               toolLife: 50000,
//               actualToolLife: 60000,
//             },
//             {
//               stationNo: "4",
//               toolNo: "u67",
//               toolLife: 50000,
//               actualToolLife: 60000,
//             },
//           ],
//         },
//       ];
//     } catch (err: any) {
//       if (!err.response) {
//         return rejectWithValue(err.response.data);
//       }
//       return rejectWithValue({
//         error: {
//           message: err.response.data.message,
//           code: err.response.data.code,
//         },
//       });
//     }
//   }
// );

export const addBoltForgingQuality = createAsyncThunk<any[], void>(
  "addBoltForgingQuality/addBoltForgingQuality",
  async (boltQuality: any) => {
    const response = await api.post(
      `${AppConstants.api}/bolt-quality`,
      boltQuality
    );

    return response.data.data;
  }
);

export const fetchBoltForgingQualityPlanInfo = createAsyncThunk<
  boltForgingQualityPlanInfo[],
  { id: string; date: string }
>(
  "boltForgingquality/fetchBoltForgingQualityPlanInfo",
  async (payload, { rejectWithValue }) => {
    try {
      const response = await api.get<
        ApplicationResponse<boltForgingQualityPlanInfo[]>
      >(
        `${AppConstants.api}/bolt-quality/info?id=${payload.id}&date=${payload.date}`
      );
      if (response.data.error !== null) {
        showErrorToast(response.data.error.message);
        return rejectWithValue(response.data.error);
      }
      console.log("Response", response.data.data);
      return response.data.data as boltForgingQualityPlanInfo[];
      // return [
      //   {
      //     id: "8cf37137-eb3e-4955-837b-b92e6bdb030b",
      //     machine_id: "765c3f50-6793-49da-b54a-0c283f58ced2",
      //     machine_name: "DHanfcgvhbj",
      //     current_plan_id: "5993d3c7-27af-4312-b78a-4d4e23755e07",
      //     rm_size: "9.6",
      //     rm_grade: 15000.0,
      //     customer: "customer12",
      //     drawing_no: "SO79-40",
      //     checkpoint: "OK",
      //     shift_id: "e5d98f92-0d29-4b75-818d-622b0331efa8",
      //     shift_name: "Shift A",
      //     operator_id: "424f255f-f9cf-45dc-a1f6-e27d77f0a5a8",
      //     operator_name: "operator 9",
      //     inspector_id: "872ad0c4-7215-4a6e-aa29-d4817c41a402",
      //     inspector_name: "inspector40",
      //     plans: [
      //       {
      //         id: "5993d3c7-27af-4312-b78a-4d4e23755e07",
      //       },
      //     ],
      //   },
      // ] as boltForgingQualityPlanInfo[];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

const checksheetslice = createSlice({
  name: "checksheet",
  initialState: {
    boltForgingMinimal: { status: "loading", error: null, data: [] },
    boltForgingQualityMinimal: { status: "loading", error: null, data: [] },
    shiftMinimal: { status: "loading", error: null, data: [] },
    boltForgingQualityPlanInfoMinimal: {
      status: "loading",
      error: null,
      data: [],
    },
  } as CheckSheetStore,

  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBoltForgingQualityChecksheet.pending, (state, action) => {
        state.boltForgingQualityMinimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchBoltForgingQualityChecksheet.fulfilled, (state, action) => {
        state.boltForgingQualityMinimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchBoltForgingQualityChecksheet.rejected, (state, action) => {
        state.boltForgingQualityMinimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      })
      .addCase(fetchBoltForgingChecksheet.pending, (state, action) => {
        state.boltForgingMinimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchBoltForgingChecksheet.fulfilled, (state, action) => {
        state.boltForgingMinimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchBoltForgingChecksheet.rejected, (state, action) => {
        state.boltForgingMinimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      })
      .addCase(fetchShiftList.pending, (state, action) => {
        state.shiftMinimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchShiftList.fulfilled, (state, action) => {
        state.shiftMinimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchShiftList.rejected, (state, action) => {
        state.shiftMinimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      })
      .addCase(fetchBoltForgingQualityPlanInfo.pending, (state, action) => {
        state.boltForgingQualityPlanInfoMinimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchBoltForgingQualityPlanInfo.fulfilled, (state, action) => {
        state.boltForgingQualityPlanInfoMinimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchBoltForgingQualityPlanInfo.rejected, (state, action) => {
        state.boltForgingQualityPlanInfoMinimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      });
  },
});
export default checksheetslice.reducer;
