import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { ProductionPlanningModelRef, ProductionPlanningStore } from "../domain/production-planning.model";
import AppConstants from "@src/utils/app.constants";
import api from "@src/common/services/api.service";
import { ApplicationResponse } from "@src/common/domain/common.model";

// export const fetchProductionPlanningList = createAsyncThunk<any[],void> ("productionPlanning/fetchProductionPlanning",
//     async () => {

//         return [
//             {
//                 customer_name: "TATA",
//                 machine: "1/2 NAT 3",
//                 drawing_number: "S071-30",
//                 description:"M10X1.5X30 HEX HEAD SCREW",
//                 rm_grade: 15825,
//                 final_rm_size:9.70,
//                 wt_pic: 25.36,
//                 planned_quantity: 386000,
//                 total_forged: 40000,
//                 bal_to_forge: 40000,
//                 weight: 40000,
//                 shift_a_forging: 17000,
//                 shift_a_weight: 400,
//                 shift_b_forging: 17000,
//                 shift_b_weight: 400,
//                 total_quantity: 26000,
//                 total_weight: 2600
//             },
//             {
//                 customer_name: "ALL",
//                 machine: "1/2 NAT 3",
//                 drawing_number: "S071-30",
//                 description:"M10X1.5X30 HEX HEAD SCREW",
//                 rm_grade: 15825,
//                 final_rm_size:9.70,
//                 wt_pic: 25.36,
//                 planned_quantity: 386000,
//                 total_forged: 40000,
//                 bal_to_forge: 40000,
//                 weight: 40000,
//                 shift_a_forging: 17000,
//                 shift_a_weight: 400,
//                 shift_b_forging: 17000,
//                 shift_b_weight: 400,
//                 total_quantity: 26000,
//                 total_weight: 2600
//             },
//             {
//                 customer_name: "TATA",
//                 machine: "1/2 NAT 3",
//                 drawing_number: "S071-30",
//                 description:"M10X1.5X30 HEX HEAD SCREW",
//                 rm_grade: 15825,
//                 final_rm_size:9.70,
//                 wt_pic: 25.36,
//                 planned_quantity: 386000,
//                 total_forged: 40000,
//                 bal_to_forge: 40000,
//                 weight: 40000,
//                 shift_a_forging: 17000,
//                 shift_a_weight: 400,
//                 shift_b_forging: 17000,
//                 shift_b_weight: 400,
//                 total_quantity: 26000,
//                 total_weight: 2600
//             },
//             {
//                 customer_name: "TATA",
//                 machine: "1/2 NAT 3",
//                 drawing_number: "S071-30",
//                 description:"M10X1.5X30 HEX HEAD SCREW",
//                 rm_grade: 15825,
//                 final_rm_size:9.70,
//                 wt_pic: 25.36,
//                 planned_quantity: 386000,
//                 total_forged: 40000,
//                 bal_to_forge: 40000,
//                 weight: 40000,
//                 shift_a_forging: 17000,
//                 shift_a_weight: 400,
//                 shift_b_forging: 17000,
//                 shift_b_weight: 400,
//                 total_quantity: 26000,
//                 total_weight: 2600
//             },
//             {
//                 customer_name: "TATA",
//                 machine: "1/2 NAT 3",
//                 drawing_number: "S071-30",
//                 description:"M10X1.5X30 HEX HEAD SCREW",
//                 rm_grade: 15825,
//                 final_rm_size:9.70,
//                 wt_pic: 25.36,
//                 planned_quantity: 386000,
//                 total_forged: 40000,
//                 bal_to_forge: 40000,
//                 weight: 40000,
//                 shift_a_forging: 17000,
//                 shift_a_weight: 400,
//                 shift_b_forging: 17000,
//                 shift_b_weight: 400,
//                 total_quantity: 26000,
//                 total_weight: 2600
//             },
//             {
//                 customer_name: "TATA",
//                 machine: "1/2 NAT 3",
//                 drawing_number: "S071-30",
//                 description:"M10X1.5X30 HEX HEAD SCREW",
//                 rm_grade: 15825,
//                 final_rm_size:9.70,
//                 wt_pic: 25.36,
//                 planned_quantity: 386000,
//                 total_forged: 40000,
//                 bal_to_forge: 40000,
//                 weight: 40000,
//                 shift_a_forging: 17000,
//                 shift_a_weight: 400,
//                 shift_b_forging: 17000,
//                 shift_b_weight: 400,
//                 total_quantity: 26000,
//                 total_weight: 2600
//             },
           
//         ]
//     }
// )

export const fetchProductionPlanningList = createAsyncThunk<ProductionPlanningModelRef[], void>(
    "productionPlanning/fetchProductionPlanning",
    async(_,{ rejectWithValue }) => {
        try{
            const url = `${AppConstants.api}/plan`;
            const response = await api.get<ApplicationResponse<ProductionPlanningModelRef[]>>(url);
              if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      return response.data.data || [];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
    }
)

const productionPlanningSlice = createSlice({
    name: "productionPlanning",
    initialState: {minimal:{status: "loading",error: null,data:[]}} as ProductionPlanningStore,
    reducers:{

    },
    extraReducers:(builder) => {
        builder
             .addCase(fetchProductionPlanningList.pending, (state, action) => {
                state.minimal = {
                    status: "loading",
                    data: [],
                    error: null
                }
            })
            .addCase(fetchProductionPlanningList.fulfilled, (state, action) => {
                state.minimal = {
                    status: "idle",
                    data: action.payload,
                    error: null
                }
            })
            .addCase(fetchProductionPlanningList.rejected, (state, action) => {
                state.minimal = {
                    status: "failed",
                    data: [],
                    error: action.error
                }
            })

    }
})

export default productionPlanningSlice.reducer