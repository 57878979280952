import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import AppConstants from "@utils/app.constants";
import api from "@common/services/api.service";
import { AuthStore, buildUserFromToken, User } from "@auth/domain/auth.config";
import { ApplicationResponse, StateTrackedData } from "@common/domain/common.model";

import { jwtDecode } from "jwt-decode";

const initialState: AuthStore = {
  status: "LOGGED_OUT",
  user: { error: null, status: "idle", data: undefined },
  token: { error: null, status: "idle", data: undefined },
};
export const login = createAsyncThunk<
  { user: User; token: string } | undefined,
  { username: string; password: string }
>("auth/login", async (payload) => {

  const queryParams = new URLSearchParams({
          username: payload.username,
          password: payload.password,
        }).toString();
  const response = await api.post<ApplicationResponse<any>>(
    `${AppConstants.api}/auth/login?${queryParams}`,
    payload
  );
  if (response.data.data == null) return undefined;
  const token = response.data.data.token ?? "";
  const user: User = buildUserFromToken(jwtDecode(token));
  return { user, token };
});
const authSlice = createSlice({
  name: "auth",
  initialState: initialState,
  reducers: {
    logout: (state) => {
      state.status = "LOGGED_OUT";
      state.user.status = "idle";
      state.user.data = undefined;
      state.user.error = null;

      state.token.status = "idle";
      state.token.data = undefined;
      state.token.error = null;
      localStorage.removeItem('jwtToken');
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "LOADING";
        state.user.status = "loading";
        state.token.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        if (action.payload == undefined) {
          state.status = "LOGGED_OUT";
          state.user.status = "failed";
          state.user.error = "Invalid username or password";

          state.token.status = "failed";
          state.token.error = "Invalid username or password";
          return;
        }


        const token = action.payload.token;
        const decodedToken = jwtDecode(token) as any;
        const tokenExpiration = decodedToken.exp * 1000; // Convert to milliseconds
        const isTokenExpired = tokenExpiration < Date.now();

        if (isTokenExpired) {
          state.status = "LOGGED_OUT";
          state.user.status = "failed";
          state.user.error = "Token has expired";

          state.token.status = "failed";
          state.token.error = "Token has expired";
          return;}
        state.status = "LOGGED_IN";
        state.user.status = "idle";
        state.user.data = action.payload.user;

        state.token.status = "idle";
        state.token.data = action.payload.token;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "LOGGED_OUT";
        state.user.status = "failed";
        state.user.error = action.error.message;

        state.token.status = "failed";
        state.token.error = action.error.message;
      });
  },
});

export const { logout } = authSlice.actions;

export default authSlice.reducer;
