import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  BreakdownDivisonModelRef,
  HourlyBreakdownModelRef,
  HourlyProductionModelRef,
  MachineHourlyStatusStore,
  OeeChartModelRef,
} from "../domain/machine-hourly-status.data";
import { ApplicationResponse } from "@src/common/domain/common.model";
import AppConstants from "@src/utils/app.constants";
import api from "@src/common/services/api.service";

export const fetchMachineHourlyEventsList = createAsyncThunk<
  HourlyBreakdownModelRef[],
  string
>(
  "machineHourlyStatus/fetchMachineHourlyEventsList",
  async (machineId, { rejectWithValue }) => {
    try {
      const response = await api.get<
        ApplicationResponse<HourlyBreakdownModelRef[]>
      >(`${AppConstants.api}/machine-event?id=${machineId}`);
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      return response.data.data as HourlyBreakdownModelRef[];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

export const fetchMachineBreakdownDivisonList = createAsyncThunk<
  BreakdownDivisonModelRef[],
  string
>(
  "machineHourlyStatus/fetchMachineBreakdownDivisonList",
  async (machineId, { rejectWithValue }) => {
    try {
      const response = await api.get<
        ApplicationResponse<BreakdownDivisonModelRef[]>
      >(`${AppConstants.api}/machine-event/breakdown?id=${machineId}`);
      if (response.data.error !== null) {
        return rejectWithValue(response.data.error);
      }
      return response.data.data as BreakdownDivisonModelRef[];
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }
      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);



export const fetchMachineHourlyProductionList = createAsyncThunk<HourlyProductionModelRef[],string>(
  "machineHourlyStatus/fetchMachineHourlyProductionList",
  async (machineId,{ rejectWithValue}) => {
    try{
      const response = await api.get<ApplicationResponse<HourlyProductionModelRef[]>>
    (`${AppConstants.api}/machine/hourly/data?id=${machineId}`);
  if(response.data.error != null){
    return rejectWithValue(response.data.error);
  } 
return response.data.data as  HourlyProductionModelRef[];
  }catch(err: any){
    if(!err.response){
      return rejectWithValue(err.response.data);
    }
    return rejectWithValue({
      error:{
        message: err.response.data.message,
        code: err.response.data.code
      }
    })
  }
  }
)


export const fetchOeeList = createAsyncThunk<OeeChartModelRef,string>(
  "machineHourlyStatus/fetchOeeList",
  async (machineId,{ rejectWithValue}) => {
    try{
      const response = await api.get<ApplicationResponse<OeeChartModelRef>>
    (`${AppConstants.api}/machine-event/shift-performance?id=${machineId}`);
  if(response.data.error != null){
    return rejectWithValue(response.data.error);
  } 
return response.data.data as  OeeChartModelRef;

  }catch(err: any){
    if(!err.response){
      return rejectWithValue(err.response.data);
    }
    return rejectWithValue({
      error:{
        message: err.response.data.message,
        code: err.response.data.code
      }
    })
  }
  }
)



const machineHourlyStatus = createSlice({
  name: "machineHourlyStatus",
  initialState: {
    hourlyProductionMinimal: {
      status: "loading",
      error: null,
      data: [],
    },
    hourlyBreakdownMinimal: {
      status: "loading",
      error: null,
      data: [],
    },
    breakdownDivisonMinimal: {
      status: "loading",
      error: null,
      data: [],
    },
    oeeMinimal: {
      status: "loading",
      error: null,
      data: {} as OeeChartModelRef,
    },
  } as MachineHourlyStatusStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachineHourlyEventsList.pending, (state) => {
        state.hourlyBreakdownMinimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchMachineHourlyEventsList.fulfilled, (state, action) => {
        state.hourlyBreakdownMinimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchMachineHourlyEventsList.rejected, (state, action) => {
        state.hourlyBreakdownMinimal = {
          status: "failed",
          data: [],
          error: action.payload,
        };
      })
      .addCase(fetchMachineBreakdownDivisonList.pending, (state) => {
        state.breakdownDivisonMinimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchMachineBreakdownDivisonList.fulfilled, (state, action) => {
        state.breakdownDivisonMinimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchMachineBreakdownDivisonList.rejected, (state, action) => {
        state.breakdownDivisonMinimal = {
          status: "failed",
          data: [],
          error: action.payload,
        };
      })
      .addCase(fetchMachineHourlyProductionList.pending, (state) => {
        state.hourlyProductionMinimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchMachineHourlyProductionList.fulfilled, (state, action) => {
        state.hourlyProductionMinimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchMachineHourlyProductionList.rejected, (state, action) => {
        state.hourlyProductionMinimal = {
          status: "failed",
          data: [],
          error: action.payload,
        };
      })
      .addCase(fetchOeeList.pending, (state) => {
        state.oeeMinimal = {
          status: "loading",
          data: {} as OeeChartModelRef,
          error: null,
        };
      })
      .addCase(fetchOeeList.fulfilled, (state, action) => {
        state.oeeMinimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchOeeList.rejected, (state, action) => {
        state.oeeMinimal = {
          status: "failed",
          data: {} as OeeChartModelRef,
          error: action.payload,
        };
      });
  },
});

export default machineHourlyStatus.reducer;
