import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  BestAndWorstMachinesModelRef,
  BestAndWorstOperatorsModelRef,
  BestAndWorstStore,
} from "@src/views/best-and-worst-operator/domain/best-and-worst.model";
import BestAndWorst from "../../views/best-and-worst-operator/index";
import AppConstants from "@src/utils/app.constants";
import api from "@src/common/services/api.service";
import { ApplicationResponse } from "@src/common/domain/common.model";
import { SearchModelRef } from "@src/views/products/domain/products.model";

type Item = {
  category: "BEST" | "WORST";
  [key: string]: any;
};

function categorizeItems(items: any[]): { best: Item[]; worst: Item[] } {
  const result = {
    best: [] as Item[],
    worst: [] as Item[],
  };

  items.forEach((item) => {
    if (item.category === "BEST") {
      result.best.push(item);
    } else if (item.category === "WORST") {
      result.worst.push(item);
    }
  });

  return result;
}

export const fetchBestAndWorstOperatorsList = createAsyncThunk<
  {
    best: BestAndWorstOperatorsModelRef[];
    worst: BestAndWorstOperatorsModelRef[];
  },
  any
>(
  "bestAndWorstOperators/fetchBestAndWorstOperatorsList",
  async (date, { rejectWithValue }) => {
    try {
      const url = `${AppConstants.api}/best-and-worst/operators?date=${date}`;
      const response = await api.get<
        ApplicationResponse<{
          best: BestAndWorstOperatorsModelRef[];
          worst: BestAndWorstOperatorsModelRef[];
        }>
      >(url);
      // if (response.data.error !== null) {
      //   return rejectWithValue(response.data.error);
      // }
      return categorizeItems(response.data as any) as {
        best: BestAndWorstOperatorsModelRef[];
        worst: BestAndWorstOperatorsModelRef[];
      };
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }

      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);

export const fetchBestAndWorstMachinesList = createAsyncThunk<
  {
    best: BestAndWorstMachinesModelRef[];
    worst: BestAndWorstMachinesModelRef[];
  },
  any
>(
  "bestAndWorstMachines/fetchBestAndWorstMachinesList",
  async (date, { rejectWithValue }) => {
    try {
      const url = `${AppConstants.api}/best-and-worst/machines?date=${date}`;
      const response = await api.get<
        ApplicationResponse<{
          best: BestAndWorstMachinesModelRef[];
          worst: BestAndWorstMachinesModelRef[];
        }>
      >(url);
      // if (response.data.error !== null) {
      //   return rejectWithValue(response.data.error);
      // }
      return categorizeItems(response.data as any) as {
        best: BestAndWorstMachinesModelRef[];
        worst: BestAndWorstMachinesModelRef[];
      };
    } catch (err: any) {
      if (!err.response) {
        return rejectWithValue(err.response.data);
      }

      return rejectWithValue({
        error: {
          message: err.response.data.message,
          code: err.response.data.code,
        },
      });
    }
  }
);
const bestAndWorstSlice = createSlice({
  name: "bestAndWorst",
  initialState: {
    bestAndWorstOperatorsminimal: {
      data: {
        best: [],
        worst: [],
      },
      status: "loading",
      error: null,
    },
    bestAndWorstMachinesminimal: {
      data: {
        best: [],
        worst: [],
      },
      status: "loading",
      error: null,
    },
  } as BestAndWorstStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchBestAndWorstOperatorsList.pending, (state, action) => {
        state.bestAndWorstOperatorsminimal = {
          status: "loading",
          data: {
            best: [],
            worst: [],
          },
          error: null,
        };
      })
      .addCase(fetchBestAndWorstOperatorsList.fulfilled, (state, action) => {
        state.bestAndWorstOperatorsminimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchBestAndWorstOperatorsList.rejected, (state, action) => {
        state.bestAndWorstOperatorsminimal = {
          status: "failed",
          data: {
            best: [],
            worst: [],
          },
          error: action.error,
        };
      })
      .addCase(fetchBestAndWorstMachinesList.pending, (state, action) => {
        state.bestAndWorstMachinesminimal = {
          status: "loading",
          data: {
            best: [],
            worst: [],
          },
          error: null,
        };
      })
      .addCase(fetchBestAndWorstMachinesList.fulfilled, (state, action) => {
        state.bestAndWorstMachinesminimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchBestAndWorstMachinesList.rejected, (state, action) => {
        state.bestAndWorstMachinesminimal = {
          status: "failed",
          data: {
            best: [],
            worst: [],
          },
          error: action.error,
        };
      });
  },
});

export default bestAndWorstSlice.reducer;
