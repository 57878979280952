import {createAsyncThunk, createSlice} from "@reduxjs/toolkit";
import api from "@src/common/services/api.service";
import AppConstants from "@src/utils/app.constants";
import {MachineStatusStore} from "../domain/machine-status.model";
import {showErrorToast} from "@utils/toast.utils";

export const fetchMachineStatusList = createAsyncThunk<any[], void>(
  "machineStatus/fetchMachineStatus",
  async () => {
   const response = await api.get(`${AppConstants.api}/plan/machine-status`);
    return response.data.data;
  }
);

export const fetchMachineStatusInfo = createAsyncThunk<any, string>(
  "machineStatus/machineStatusInfo",
  async (id) => {
    // const response = await api.get(`${AppConstants.api}/machine-status/${id}`);
    // return response.data;
    return {
      machine: "NATIONAL-I",
      status: "Running",
      product: "PFLXXXX",
      size: "M12x70",
      wire_grade: "11.70-10b21",
      quantity_loaded: 50000,
      quantity_produced: 23000,
      speed: 50,
      tool_1_life: 10000,
      bal_1_tool_life: 10000,
      tool_2_life: 10000,
      bal_2_tool_life: 10000,
      tool_3_life: 10000,
      bal_3_tool_life: 10000,
      tool_4_life: 10000,
      bal_4_tool_life: 10000,
      day_production: 10000,
      per_hour_production: 23,
      oee: "36%",
      wire_balance_quantity: 20000,
      opr: "ABX",
      inspector: "XYZ",
      ng_count: 2,
    };
  }
);

export const fetchMachineRunningChartData = createAsyncThunk<any, string>(
  "machineStatus/machineRunningChartData",
  async (id: string) => {
    // const response = await api.get(`${AppConstants.api}/machine-status/${id}/running-chart`);
    // return response.data;
    return {
      availability: {
        target: 50,
        current: 67,
      },
      quality: {
        target: 80,
        current: 89,
      },
      performance: {
        target: 90,
        current: 50,
      },
      oee: {
        target: 90,
        current: 60,
      },
    };
  }
);

export const addNg = createAsyncThunk<any, {
  id: string,
  data: any
}>("machineStatus/machineRunningChartData", async (payload) => {
  try {
    const response = await api.post(`${AppConstants.api}/url/id=${payload.id}`, payload.data);
    if (response.data.error != null) {
      showErrorToast("Something went wrong while adding NG.")
      return
    }
    return response.data.data;

  } catch (err: any) {
    console.log(err)
  }

})

const machineStatusSlice = createSlice({
  name: "machineStatus",
  initialState: {
    minimal: {
      status: "loading",
      error: null,
      data: [],
    },
    info: {
      status: "loading",
      error: null,
      data: {},
    },
    runningChart: {
      status: "loading",
      error: null,
      data: {},
    },
  } as MachineStatusStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchMachineStatusList.pending, (state, action) => {
        state.minimal.status = "loading";
        state.minimal.data = [];
        state.minimal.error = null;
      })
      .addCase(fetchMachineStatusList.fulfilled, (state, action) => {
        state.minimal.status = "idle";
        state.minimal.data = action.payload;
        state.minimal.error = null;
      })
      .addCase(fetchMachineStatusList.rejected, (state, action) => {
        state.minimal.status = "failed";
        state.minimal.data = [];
        state.minimal.error = null;
      });
    builder
      .addCase(fetchMachineStatusInfo.pending, (state, action) => {
        state.info.status = "loading";
        state.info.data = {};
        state.info.error = null;
      })
      .addCase(fetchMachineStatusInfo.fulfilled, (state, action) => {
        state.info.status = "idle";
        state.info.data = action.payload;
        state.info.error = null;
      })
      .addCase(fetchMachineStatusInfo.rejected, (state, action) => {
        state.info.status = "failed";
        state.info.data = {};
        state.info.error = null;
      });
    builder
      .addCase(fetchMachineRunningChartData.pending, (state, action) => {
        state.runningChart.status = "loading";
        state.runningChart.data = {};
        state.runningChart.error = null;
      })
      .addCase(fetchMachineRunningChartData.fulfilled, (state, action) => {
        state.runningChart.status = "idle";
        state.runningChart.data = action.payload;
        state.runningChart.error = null;
      })
      .addCase(fetchMachineRunningChartData.rejected, (state, action) => {
        state.runningChart.status = "failed";
        state.runningChart.data = {};
        state.runningChart.error = null;
      });
  },
});

export default machineStatusSlice.reducer;
