import Lottie from "lottie-react"
import React from "react"

import loader from "@assets/animations/loader.json"

export default function Loader() {
    return (
        <div className="bg-white h-screen w-screen flex justify-center items-center">
            <Lottie animationData={loader} loop/>
        </div>
    )
}
