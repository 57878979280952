import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  ApplicationResponse,
  StateTrackedData,
} from "@src/common/domain/common.model";
import api from "@src/common/services/api.service";
import AppConstants from "@src/utils/app.constants";
import { ProductsStore } from "@src/views/products/domain/products.model";

type LocationModelRef = { id: string; name: string };
type LocationsStore = {
  minimal: StateTrackedData<LocationModelRef[]>;
};

export const fetchLocationList = createAsyncThunk<any[], void>(
  "locations/fetchLocationsList",
  async () => {
    const response = await api.get<ApplicationResponse<LocationModelRef[]>>(
      `${AppConstants.api}/location`
    );
    return response.data.data || [];
  }
);

const locationsSlice = createSlice({
  name: "products",
  initialState: {
    minimal: { status: "loading", error: null, data: [] },
  } as LocationsStore,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchLocationList.pending, (state, action) => {
        state.minimal = {
          status: "loading",
          data: [],
          error: null,
        };
      })
      .addCase(fetchLocationList.fulfilled, (state, action) => {
        state.minimal = {
          status: "idle",
          data: action.payload,
          error: null,
        };
      })
      .addCase(fetchLocationList.rejected, (state, action) => {
        state.minimal = {
          status: "failed",
          data: [],
          error: action.error,
        };
      });
  },
});

export default locationsSlice.reducer;
